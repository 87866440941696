import { USER_API } from '@/api/user';
import { AppConfigStateType } from '@/models/appConfig';
import { useDispatch, useModel, useMutation, useSelector } from '@umijs/max';
import { PropsWithChildren, useEffect } from 'react';

const AppConfigComponent = ({ children }: PropsWithChildren) => {
  const { initialState } = useModel('@@initialState');
  const { currentUser } = initialState || {};
  const { data = {} as AppConfigStateType['appConfig']['data'] }: AppConfigStateType['appConfig'] =
    useSelector((state: AppConfigStateType) => state.appConfig);

  const { mutateAsync } = useMutation({
    mutationFn: USER_API.fetchConfig,
  });

  const dispatch = useDispatch();

  const fetchAppConfig = async () => {
    const response = await mutateAsync();
    return response;
  };

  useEffect(() => {
    if (!currentUser) return;
    if (data?.version !== currentUser?.configVersion) {
      fetchAppConfig().then((res) => {
        if (res?.status === 200) {
          const { data = {} } = res?.data;
          dispatch({
            type: 'appConfig/saveAppConfig',
            payload: data,
          });
        }
      });
    }

    return () => {};
  }, []);

  return <>{children}</>;
};

export default AppConfigComponent;
