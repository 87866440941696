import { AvatarDropdown, AvatarName } from '@/components';
import Broadcast from '@/utils/broadcast';
import type { Settings as LayoutSettings } from '@ant-design/pro-components';
import { SettingDrawer } from '@ant-design/pro-components';
import type { RunTimeLayoutConfig } from '@umijs/max';
import { history } from '@umijs/max';
import { message } from 'antd';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { PersistConfig, persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { COLOR } from '../config/common';
import defaultSettings from '../config/defaultSettings';
import { CLIENT_API, USER_API, USER_API_TYPE } from './api';
import AppConfigComponent from './components/appConfigComponent';
import Exception from './components/Exception';
import { UserRole } from './interfaces';
import { errorConfig } from './requestErrorConfig';
import { LOCAL_PATH } from './utils';
dayjs.extend(utc);
dayjs.extend(timezone);
const { broadcastAction, channel } = Broadcast;
const isDev = process.env.NODE_ENV === 'development';

channel.addEventListener('message', (event: any) => {
  if (event.data === broadcastAction) window.location.reload();
});

const persistConfig: PersistConfig<unknown, any, any, any> = {
  timeout: 1000, // you can define your time. But is required.
  key: 'root',
  storage,
  whitelist: ['tablesTools', 'cachedFilters', 'appConfig'],
};

const persistEnhancer =
  () => (createStore: any) => (reducer: any, initialState: any, enhancer: any) => {
    const store = createStore(persistReducer(persistConfig, reducer), initialState, enhancer);
    const persist = persistStore(store, null);
    return {
      persist,
      ...store,
    };
  };

export const dva =
  process.env.REACT_APP_ENV === 'prod'
    ? {
        config: {
          extraEnhancers: [persistEnhancer()],
          onError(e: Error) {
            message.error(e.message, 3);
          },
        },
      }
    : {
        config: {
          extraEnhancers: [persistEnhancer()],
          onError(e: Error) {
            message.error(e.message, 3);
          },
        },
        plugins: [
          {
            // onAction: createLogger(),
          },
        ],
      };

/**
 * @see  https://umijs.org/zh-CN/plugins/plugin-initial-state
 * */
export async function getInitialState(): Promise<{
  settings?: Partial<LayoutSettings>;
  currentUser?: USER_API_TYPE['CurrentUser'];
  loading?: boolean;
  fetchUserInfo?: () => Promise<USER_API_TYPE['CurrentUser'] | undefined>;
  clients?: CommonTypes.ClientType[];
}> {
  let currentUser, clients;

  const { location } = history;

  const fetchUserInfo = async () => {
    try {
      const response = await USER_API.currentUser();
      return response.data.data;
    } catch (error) {
      if (
        location.pathname.includes(LOCAL_PATH.forgotPassword) ||
        location.pathname.includes(LOCAL_PATH.resetPassword)
      ) {
        history.push(location.pathname);
        return;
      }
      history.push(LOCAL_PATH.login);
    }
    return undefined;
  };
  // If it is not a login page, execute
  if (location.pathname !== LOCAL_PATH.login) {
    currentUser = await fetchUserInfo();
    const role = currentUser?.role;

    if (role === UserRole.ADMIN) {
      clients = await CLIENT_API.getClientsName();
    }
  }

  return {
    fetchUserInfo,
    currentUser,
    settings: defaultSettings as Partial<LayoutSettings>,
    clients,
  };
}

export const layout: RunTimeLayoutConfig = ({ initialState, setInitialState }) => {
  return {
    actionsRender: () => [
      //Note: disabled question FAQ for now.
      // <Question key="doc" />,
      //Note: Disabled Language selector for now.
      //<SelectLang key="SelectLang" />
    ],
    avatarProps: {
      src: null,
      title: <AvatarName />,
      render: (_, avatarChildren) => {
        return <AvatarDropdown>{avatarChildren}</AvatarDropdown>;
      },
    },
    // waterMark Props: {
    //   context: initial State?.current User?.name,
    // },
    // footerRender: () => <Footer />,
    onPageChange: () => {
      const { location } = history;
      // If not logged in, redirect to login
      if (!initialState?.currentUser && location.pathname !== LOCAL_PATH.login) {
        history.push(LOCAL_PATH.login);
      }
    },
    layoutBgImgList: [
      {
        src: 'https://mdn.alipayobjects.com/yuyan_qk0oxh/afts/img/D2LWSqNny4sAAAAAAAAAAAAAFl94AQBr',
        left: 85,
        bottom: 100,
        height: '303px',
      },
      {
        src: 'https://mdn.alipayobjects.com/yuyan_qk0oxh/afts/img/C2TWRpJpiC0AAAAAAAAAAAAAFl94AQBr',
        bottom: -68,
        right: -45,
        height: '303px',
      },
      {
        src: 'https://mdn.alipayobjects.com/yuyan_qk0oxh/afts/img/F6vSTbj8KpYAAAAAAAAAAAAAFl94AQBr',
        bottom: 0,
        left: 0,
        width: '331px',
      },
    ],
    // links: isDev
    //   ? [
    //       <Link key="openapi" to="/umi/plugin/openapi" target="_blank">
    //         <LinkOutlined />
    //         <span>OpenAPI Documentation</span>
    //       </Link>,
    //     ]
    //   : [],
    menuHeaderRender: undefined,
    // Custom 403 page
    unAccessible: <Exception noAccessible />,
    // Add a loading state
    childrenRender: (children) => {
      // if (initialState?.loading) return <PageLoading />;
      return (
        <>
          <AppConfigComponent>{children}</AppConfigComponent>
          {isDev && (
            <SettingDrawer
              disableUrlParams
              enableDarkTheme
              settings={initialState?.settings}
              onSettingChange={(settings) => {
                setInitialState((preInitialState) => ({
                  ...preInitialState,
                  settings,
                }));
              }}
            />
          )}
        </>
      );
    },
    ...initialState?.settings,
    token: {
      header: {
        colorBgHeader: COLOR['@primary-color'],
      },
    },
  };
};

/**
 * @name request configuration, you can configure error handling
 * It provides a unified network request and error handling scheme based on the useRequest of axios and ahooks.
 * @doc https://umijs.org/docs/max/request#Configuration
 */
export const request = {
  ...errorConfig,
};
