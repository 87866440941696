// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import HomeOutlined from '/codebuild/output/src3921546798/src/node_modules/@ant-design/icons/es/icons/HomeOutlined';
import UserOutlined from '/codebuild/output/src3921546798/src/node_modules/@ant-design/icons/es/icons/UserOutlined';
import UnorderedListOutlined from '/codebuild/output/src3921546798/src/node_modules/@ant-design/icons/es/icons/UnorderedListOutlined';
import BankOutlined from '/codebuild/output/src3921546798/src/node_modules/@ant-design/icons/es/icons/BankOutlined';
import AlertOutlined from '/codebuild/output/src3921546798/src/node_modules/@ant-design/icons/es/icons/AlertOutlined';
import DownloadOutlined from '/codebuild/output/src3921546798/src/node_modules/@ant-design/icons/es/icons/DownloadOutlined';
export default { HomeOutlined, UserOutlined, UnorderedListOutlined, BankOutlined, AlertOutlined, DownloadOutlined };
