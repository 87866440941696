import { Button, Result } from 'antd';
import React from 'react';
import { history } from 'umi';

const Exception: React.FC<{
    notFound?: React.ReactNode;
    noAccessible?: React.ReactNode;
    unAccessible?: React.ReactNode;
}> = ({ notFound }) => (

    <Result
        status={notFound ? '404' : '403'}
        title={notFound ? '404' : '403'}
        subTitle={notFound ? 'Sorry, the page you visited does not exist' : 'Sorry, you do not have permission to access this page'}
        extra={
            <Button type="primary" onClick={() => history.push('/')}>
                Back to home
            </Button>
        }
    />
);

export default Exception;