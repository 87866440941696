// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import model_1 from '/codebuild/output/src3921546798/src/src/models/appConfig';
import model_2 from '/codebuild/output/src3921546798/src/src/models/cachedFilter';
import model_3 from '/codebuild/output/src3921546798/src/src/models/clients';
import model_4 from '/codebuild/output/src3921546798/src/src/models/contextMenu';
import model_5 from '/codebuild/output/src3921546798/src/src/models/modalTools';
import model_6 from '/codebuild/output/src3921546798/src/src/models/tablesTools';

export const models = {
model_1: { namespace: 'appConfig', model: model_1 },
model_2: { namespace: 'cachedFilter', model: model_2 },
model_3: { namespace: 'clients', model: model_3 },
model_4: { namespace: 'contextMenu', model: model_4 },
model_5: { namespace: 'modalTools', model: model_5 },
model_6: { namespace: 'tablesTools', model: model_6 },
} as const
