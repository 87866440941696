export default {
  'menu.welcome': 'خوش آمدید',
  'menu.more-blocks': 'بلوک های بیشتر',
  'menu.home': 'خانه',
  'menu.admin': 'مدیر',
  'menu.admin.sub-page': 'زیر صفحه',
  'menu.login': 'ورود',
  'menu.register': 'ثبت نام',
  'menu.register-result': 'ثبت نام نتیجه',
  'menu.dashboard': 'داشبورد',
  'menu.dashboard.analysis': 'تحلیل و بررسی',
  'menu.dashboard.monitor': 'نظارت',
  'menu.dashboard.workplace': 'محل کار',
  'menu.exception.403': '403',
  'menu.exception.404': '404',
  'menu.exception.500': '500',
  'menu.form': 'فرم',
  'menu.form.basic-form': 'فرم اساسی',
  'menu.form.step-form': 'فرم مرحله',
  'menu.form.step-form.info': 'فرم مرحله (نوشتن اطلاعات انتقال)',
  'menu.form.step-form.confirm': 'فرم مرحله (تأیید اطلاعات انتقال)',
  'menu.form.step-form.result': 'فرم مرحله (تمام شده)',
  'menu.form.advanced-form': 'فرم پیشرفته',
  'menu.list': 'لیست',
  'menu.list.table-list': 'جدول جستجو',
  'menu.list.basic-list': 'لیست اصلی',
  'menu.list.card-list': 'لیست کارت',
  'menu.list.search-list': 'لیست جستجو',
  'menu.list.search-list.articles': 'لیست جستجو (مقالات)',
  'menu.list.search-list.projects': 'لیست جستجو (پروژه ها)',
  'menu.list.search-list.applications': 'لیست جستجو (برنامه ها)',
  'menu.profile': 'مشخصات',
  'menu.profile.basic': 'مشخصات عمومی',
  'menu.profile.advanced': 'مشخصات پیشرفته',
  'menu.result': 'نتیجه',
  'menu.result.success': 'موفق',
  'menu.result.fail': 'ناموفق',
  'menu.exception': 'استثنا',
  'menu.exception.not-permission': '403',
  'menu.exception.not-find': '404',
  'menu.exception.server-error': '500',
  'menu.exception.trigger': 'راه اندازی',
  'menu.account': 'حساب',
  'menu.account.center': 'مرکز حساب',
  'menu.account.settings': 'تنظیمات حساب',
  'menu.account.trigger': 'خطای راه اندازی',
  'menu.account.logout': 'خروج',
  'menu.editor': 'ویرایشگر گرافیک',
  'menu.editor.flow': 'ویرایشگر جریان',
  'menu.editor.mind': 'ویرایشگر ذهن',
  'menu.editor.koni': 'ویرایشگر Koni',
};
