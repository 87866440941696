import { tableSorting } from '@/utils/api.util';
import { CHARGEBACK_LIST } from '@/utils/datatable.index';
import { RcFile } from 'antd/es/upload';
import { AxiosInstance } from './http';

export const CHARGEBACK_API = {
  getChargebacks: async (
    params: CommonTypes.PageParams,
    sort: Record<string, any>,
  ): Promise<CommonTypes.ChargebackListItem[]> => {
    const { current = 1, pageSize = 10, ...filters }: any = params;

    Object.entries(filters).forEach(([key, value]: [string, any]) => {
      if (key === CHARGEBACK_LIST.status && value?.length === 0) filters[key] = null;
      else if (value === 'None' || !value) delete filters[key];

      if (key === CHARGEBACK_LIST.amount)
        filters[key] = parseInt(Math.abs(parseFloat(String(value * 100))).toFixed(0));
    });

    const finalFilter = {
      ...filters,
      status: filters?.status || null,
      case_date: filters?.case_date || null,
      txn_date: filters?.txn_date || null,
    };

    const payload = {
      page: current,
      limit: pageSize,
      filters: finalFilter,
      sortBy: tableSorting(sort),
    };

    const { data } = await AxiosInstance.axiosRequest.post('/chargebacks', payload);
    return data.data;
  },

  resolveTransaction: async (payload: { doc_id: string; txn_id: string }) => {
    return AxiosInstance.axiosRequest.post('/chargebacks/resolve/transactions', payload);
  },

  exportChargeback: async (payload: Record<string, any>) => {
    if (payload?.amount)
      payload.amount = parseInt(Math.abs(parseFloat(String(payload.amount * 100))).toFixed(0));
    return AxiosInstance.axiosRequest.post('/chargebacks/export', payload);
  },

  manualResolve: async (payload: { chargebackId: string; crm_txn_id: string }) => {
    return AxiosInstance.axiosRequest.post('/chargebacks/manual/resolve', payload);
  },

  importChargebacks: async (payload: Record<string, string | Blob | RcFile>) => {
    return AxiosInstance.axiosRequest.post('/chargebacks/upload', payload, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  },

  getTSYSImagesByCbId: async (cbId: string): Promise<string[]> => {
    const response = await AxiosInstance.axiosRequest.get(`/chargebacks/tsys-images/${cbId}`);
    return response?.data;
  },

  getTSYSImagesSignUrl: async (objectKey: string): Promise<string> => {
    const response = await AxiosInstance.axiosRequest.post(`/chargebacks/tsys-images`, {
      objectKey,
    });
    return response?.data;
  },
};
