const initialState: Record<string, any> = {};

const store = {
  namespace: 'cachedFilters',
  state: initialState,
  reducers: {
    saveFilters(state: Record<string, any>, payload: any) {
      const filters = payload?.payload?.filters;
      const path = payload?.payload?.path;
      return {
        ...state,
        [path]: {
          filters: JSON.stringify(filters),
        },
      };
    },
    deleteFilters() {
      return {};
    },
    deleteFiltersByPath(state: Record<string, any>, payload: any) {
      const path = payload?.payload?.path;
      if (path && state[path]) delete state[path];
      return { ...state };
    },
  },

  initialState: initialState,
};

export type CachedFiltersReducer = (typeof store)['reducers'];
export type CachedFiltersStateType = { cachedFilters: typeof initialState };

export default store;
