export const enum PermissionType {
    MID_READ = "mid.read",
    MID_CREATE = "mid.create",
    MID_UPDATE = "mid.update",
    MID_DELETE = "mid.delete",
    CLIENT_READ = "client.read",
    CLIENT_CREATE = "client.create",
    CLIENT_UPDATE = "client.update",
    CLIENT_DELETE = "client.delete",
    CHARGEBACK_READ = "chargeback.read",
    CHARGEBACK_CREATE = "chargeback.create",
    CHARGEBACK_UPDATE = "chargeback.update",
    CHARGEBACK_DELETE = "chargeback.delete",
    PROFILE_READ = "profile.read",
    PROFILE_CREATE = "profile.create",
    PROFILE_UPDATE = "profile.update",
    PROFILE_DELETE = "profile.delete",
    ADMIN_READ = "admin.read",
    ADMIN_CREATE = "admin.create",
    ADMIN_UPDATE = "admin.update",
    ADMIN_DELETE = "admin.delete",
    ALERT_READ = "alert.read",
    ALERT_CREATE = "alert.create",
    ALERT_UPDATE = "alert.update",
    ALERT_DELETE = "alert.delete",
    CLIENTCBALERTCOUNT_READ = "clientCBAlertCount.read",
    CLIENTCBALERTCOUNT_CREATE = "clientCBAlertCount.create",
    CLIENTCBALERTCOUNT_UPDATE = "clientCBAlertCount.update",
    CLIENTCBALERTCOUNT_DELETE = "clientCBAlertCount.delete",
    JOB_READ = 'job.read',
    JOB_CREATE = "job.create",
    JOB_UPDATE = "job.update",
    JOB_DELETE = "job.delete",
    MC_ALERT_READ = 'mcAlert.read',
    MC_ALERT_CREATE = 'mcAlert.create',
    MC_ALERT_UPDATE = 'mcAlert.update',
    MC_ALERT_DELETE = 'mcAlert.delete',
}

export const enum UserRole {
    ADMIN = 'admin',
    CLIENT = 'client'
}

export type EventProps<T extends Record<string, any>> = {
    key: string;
    record: T;
    e: {
        key?: string;
        keyPath?: string[];
        domEvent: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>;
    };
};