import { RcFile } from 'antd/es/upload';
import { AxiosInstance } from './http';

export const MID_API = {
  getMids: async (
    params: CommonTypes.PageParams,
    sort: Record<string, any>,
  ): Promise<CommonTypes.MidListItem[]> => {
    const { current = 1, pageSize = 10, ...filters }: any = params;
    Object.entries(filters).forEach(([key, value]: [string, any]) => {
      if (value === 'None' || value.length === 0 || !value) {
        delete filters[key];
      }
    });
    let _sort: any = {}
    if (sort) {
      Object.entries(sort)?.forEach(([key, value]) => {
        if (value === 'ascend') {
          _sort[key] = 1
        }
        else if (value === 'descend') {
          _sort[key] = -1
        }
      })
    }

    const payload = {
      page: current,
      limit: pageSize,
      filters,
      sort: _sort
    };
    const { data } = await AxiosInstance.axiosRequest.post('/mids', payload);
    return data.data;
  },
  importMid: (payload: Record<string, string | Blob | RcFile>) => {
    return AxiosInstance.axiosRequest.post('/mids/upload', payload, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  },

  getDecryptedPassword: async (payload: { _id: string, fieldName: string }) => {
    const response = await AxiosInstance.axiosRequest.post('/mids/getDecryptedPassword', payload);
    return response;
  },

  createMidByAdmin: async (payload: Record<string, any>) => {
    return AxiosInstance.axiosRequest.post('/mids/create', payload);
  },

  editMidByAdmin: async (payload: Record<string, any>) => {
    return AxiosInstance.axiosRequest.put('/mids/edit', payload);
  },

  exportMid: async (payload: Record<string, string>) => {
    return AxiosInstance.axiosRequest.post('/mids/export', payload)
  },

  queueScrapJob: async (payload: Record<string, string>) => {
    return AxiosInstance.axiosRequest.post('/mids/queue-scrapJob', payload)
  },

  cbReAttemptMarking: async (payload: Record<string, string>) => {
    return AxiosInstance.axiosRequest.post('/chargebacks/reattempt/marking', payload)
  },

  bulkMIDStatusUpdate: async (payload: Record<string, string>) => {
    return AxiosInstance.axiosRequest.post('/mids/bulk-status-update', payload)
  },

  fetchVisaArn: async (mid: string) => {
    return AxiosInstance.axiosRequest.post('/mids/fetch-visa-arn', { mid })
  },

  toggleAutomationMarking: async (payload: string) => {
    return AxiosInstance.axiosRequest.post('/mids/bulk-toggle-automation-marking', payload)
  },
};
