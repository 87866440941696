export default {
  'pages.layouts.userLayout.title':
    'Ant Design is the most influential web design specification in Xihu district',
  'pages.login.accountLogin.tab': 'Account Login',
  'pages.login.accountLogin.errorMessage': 'Incorrect username/password',
  'pages.login.failure': 'Login failed, please try again!',
  'pages.login.success': 'Login successful!',
  'pages.login.username.placeholder': 'Username',
  'pages.login.username.required': 'Please input your username!',
  'pages.login.password.placeholder': 'Password',
  'pages.login.password.required': 'Please input your password!',
  'pages.login.phoneLogin.tab': 'Phone Login',
  'pages.login.phoneLogin.errorMessage': 'Verification Code Error',
  'pages.login.phoneNumber.placeholder': 'Phone Number',
  'pages.login.phoneNumber.required': 'Please input your phone number!',
  'pages.login.phoneNumber.invalid': 'Phone number is invalid!',
  'pages.login.captcha.placeholder': 'Verification Code',
  'pages.login.captcha.required': 'Please input verification code!',
  'pages.login.phoneLogin.getVerificationCode': 'Get Code',
  'pages.getCaptchaSecondText': 'sec(s)',
  'pages.login.rememberMe': 'Remember me',
  'pages.login.forgotPassword': 'Forgot Password ?',
  'pages.login.submit': 'Login',
  'pages.login.loginWith': 'Login with :',
  'pages.login.registerAccount': 'Register Account',
  'pages.welcome.link': 'Welcome',
  'pages.welcome.alertMessage': 'Faster and stronger heavy-duty components have been released.',
  'pages.admin.subPage.title': 'This page can only be viewed by Admin',
  'pages.admin.subPage.alertMessage':
    'Umi ui is now released, welcome to use npm run ui to start the experience.',
  'pages.searchTable.createForm.newRule': 'New Rule',
  'pages.searchTable.updateForm.ruleConfig': 'Rule configuration',
  'pages.searchTable.updateForm.basicConfig': 'Basic Information',
  'pages.searchTable.updateForm.ruleName.nameLabel': 'Rule Name',
  'pages.searchTable.updateForm.ruleName.nameRules': 'Please enter the rule name!',
  'pages.searchTable.updateForm.ruleDesc.descLabel': 'Rule Description',
  'pages.searchTable.updateForm.ruleDesc.descPlaceholder': 'Please enter at least five characters',
  'pages.searchTable.updateForm.ruleDesc.descRules':
    'Please enter a rule description of at least five characters!',
  'pages.searchTable.updateForm.ruleProps.title': 'Configure Properties',
  'pages.searchTable.updateForm.object': 'Monitoring Object',
  'pages.searchTable.updateForm.ruleProps.templateLabel': 'Rule Template',
  'pages.searchTable.updateForm.ruleProps.typeLabel': 'Rule Type',
  'pages.searchTable.updateForm.schedulingPeriod.title': 'Set Scheduling Period',
  'pages.searchTable.updateForm.schedulingPeriod.timeLabel': 'Starting Time',
  'pages.searchTable.updateForm.schedulingPeriod.timeRules': 'Please choose a start time!',
  'pages.searchTable.titleDesc': 'Description',
  'pages.searchTable.ruleName': 'Rule name is required',
  'pages.searchTable.titleCallNo': 'Number of Service Calls',
  'pages.searchTable.titleStatus': 'Status',
  'pages.searchTable.nameStatus.default': 'default',
  'pages.searchTable.nameStatus.running': 'running',
  'pages.searchTable.nameStatus.online': 'online',
  'pages.searchTable.nameStatus.abnormal': 'abnormal',
  'pages.searchTable.titleUpdatedAt': 'Last Scheduled at',
  'pages.searchTable.exception': 'Please enter the reason for the exception!',
  'pages.searchTable.titleOption': 'Option',
  'pages.searchTable.config': 'Configuration',
  'pages.searchTable.subscribeAlert': 'Subscribe to alerts',
  'pages.searchTable.title': 'Enquiry Form',
  'pages.searchTable.new': 'New',
  'pages.searchTable.chosen': 'chosen',
  'pages.searchTable.item': 'item',
  'pages.searchTable.totalServiceCalls': 'Total Number of Service Calls',
  'pages.searchTable.tenThousand': '0000',
  'pages.searchTable.batchDeletion': 'batch deletion',
  'pages.searchTable.batchApproval': 'batch approval',
  'pages.ManageMid.id': 'Id',
  'pages.ManageMid.clientId': 'Client',
  'pages.ManageMid.dba': 'DBA',
  'pages.ManageMid.descriptor': 'Descriptor',
  'pages.ManageMid.rdrVerifiName': 'RDR Verifi Name',
  'pages.ManageMid.manage': 'Manage',
  'pages.ManageMid.email': 'Email',
  'pages.ManageMid.crmId': 'GWID',
  'pages.ManageMid.updatedAt': 'Updated At',
  'pages.ManageMid.action': 'Action',
  'pages.ManageMid.rdr': 'RDR Live Date',
  'pages.ManageMid.rpaLastRunAt': 'RPA Last Run At',
  'pages.ManageMid.cbLastImportedAt': 'CB Last Imported At',
  'pages.ManageMid.mcAlLastImportedAt': 'MC Alert Last Imported At',
  'pages.ManageMid.rdrAlLastImportedAt': 'Last RDR Import',
  'pages.ManageMid.status': 'Status',
  'pages.ManageMid.mid': 'MID #',
  'pages.ManageMid.lastCheckedAt': 'Last Checked At',
  'pages.ManageMid.processor': 'Processor',
  'pages.ManageMid.portal': 'Portal',
  'pages.ManageMid.createMid': 'Create MID',
  'pages.chargeback.card_last_4': 'Card last 4',
  'pages.chargeback.authorization_code': 'Auth Code',
  'pages.chargeback.card_first_6': 'Card Bin',
  'pages.chargeback.client': 'Client',
  'pages.ManageMid.mtdChargebacks': 'MTD Chargebacks',
  'pages.ManageMid.mtdCBPercentage': 'MTD Chargeback(%)',
  'pages.ManageMid.components.CreateMid.client': 'Please select a client',
  'pages.ManageMid.components.CreateMid.name': 'Please enter a name',
  'pages.ManageMid.components.CreateMid.midNumber': 'Please enter a MID number',
  'pages.ManageMid.components.CreateMid.descriptor': 'Please enter a descriptor',
  'pages.ManageMid.components.CreateMid.DBA': 'Please enter a DBA',
  'pages.ManageMid.components.CreateMid.website': 'Please enter a website URL',
  'pages.ManageMid.components.CreateMid.supportEmail': 'Please enter a support email',
  'pages.ManageMid.components.CreateMid.supportNumber': 'Please enter a customer support number',
  'pages.ManageMid.components.CreateMid.processorName': 'Please enter a processor name',
  'pages.ManageMid.components.CreateMid.processorTimezone': 'Please enter a processor timezone',
  'pages.ManageMid.components.CreateMid.feesPerChargeback': 'Please enter a fees per chargeback',
  'pages.ManageMid.components.CreateMid.portalURL': 'Please enter a portal URL',
  'pages.ManageMid.components.CreateMid.portalUsername': 'Please enter portal username',
  'pages.ManageMid.components.CreateMid.portalPassword': 'Please enter portal password',
  'pages.ManageMid.components.CreateMid.productID': 'Please enter a product Id',
  'pages.ManageMid.components.CreateMid.epxUsername': 'Please enter EPX username',
  'pages.ManageMid.components.CreateMid.epxPassword': 'Please enter EPX password',
  'pages.chargeback.caseNumber': 'Case Number',
  'pages.chargeback.caseDate': 'Case Date',
  'pages.chargeback.cbAmount': 'CB Amount',
  'pages.chargeback.txnDate': 'Txn Date',
  'pages.chargeback.txnAmount': 'Txn Amount',
  'pages.chargeback.cardType': 'Card Type',
  'pages.chargeback.reasonCode': 'Reason Code',
  'pages.chargeback.status': 'Status',
  'pages.chargeback.action': 'Action',
  'pages.chargeback.id': 'Id',
  'pages.chargeback.mid': 'MID',
  'pages.chargeback.processor': 'Processor',
  'pages.chargeback.arn': 'ARN',
  'pages.chargeback.Amount': 'Amount',
  'pages.chargeback.card': 'Card',
  'pages.chargeback.crmId': 'CRM GWID',
  'pages.chargeback.crmOrderId': 'CRM Order ID',
  'pages.user.firstName': 'First Name',
  'pages.user.lastName': 'Last Name',
  'pages.user.email': 'Email',
  'pages.client.name': 'Name',
  'pages.client.email': 'Email',
  'pages.client.createdAt': 'Created At',
  'pages.client.updatedAt': 'Updated At',
  'pages.client.clientShortName': 'Client Shortname',
  'pages.client.crm': 'CRM',
  'pages.client.mainContact': 'Main Contact',
  'pages.client.alternateClients': 'Alternate Clients',
  'pages.ManageClient.components.modals.AddNewClient.password': 'Please enter a Password',
  'pages.ManageClient.components.modals.AddNewClient.email': 'Please enter an Email',
  'pages.ManageClient.components.modals.AddNewClient.firstName': 'Please enter First Name',
  'pages.ManageClient.components.modals.AddNewClient.lastName': 'Please enter Last Name',
  'pages.ManageClient.components.modals.AddNewClient.clientShortName':
    'Please enter an Client Shortname',
  'pages.ManageClient.components.modals.AddNewClient.clientName':
    'Please enter a Company/Organization Name',
  'pages.ManageClient.components.modals.ManageCRM.CRM': 'Please enter a CRM',
  'pages.ManageClient.components.modals.ManageCRM.apiUsername': 'Please enter API User ID',
  'pages.ManageClient.components.modals.ManageCRM.apiPassword': 'Please enter an API Password',
  'pages.ManageClient.components.modals.ManageCRM.29NextUrl': 'Please enter 29Next Instance URL',
  'pages.ManageClient.components.modals.ManageCRM.accessToken':
    'Please enter an Admin API Access Token',
  'pages.ManageClient.components.modals.ManageCRM.apiUrl': 'Please enter an API URL',
  'pages.ManageClient.components.modals.ManageCRM.sublyticsApiUrl':
    'Please enter Sublytics Instance URL',
  'pages.Dashboard.alertCount': 'RDR Alerts',
  'pages.Dashboard.cbCount': 'CB',
  'pages.Dashboard.GWID': 'GWID',
  'pages.Dashboard.mid': 'MID',
  'pages.Dashboard.clientShortName': 'Client',
  'pages.Dashboard.cbMarkedCount': 'CB Marked',
  'pages.Dashboard.cbErrorCount': 'CB Errors',
  'pages.Dashboard.alertTierCount1': 'RDR - T1',
  'pages.Dashboard.alertTierCount2': 'RDR - T2',
  'pages.Dashboard.alertErrorCount': 'RDR Errors',
  'pages.Dashboard.caseDate': 'Case Date',
  'pages.Dashboard.rdrMarkedCount': 'RDR Marked',
  'pages.MCAlert.id': 'ID',
  'pages.MCAlert.caseId': 'Case Id',
  'pages.MCAlert.client': 'Client',
  'pages.MCAlert.mid': 'MID',
  'pages.MCAlert.amount': 'Amount',
  'pages.MCAlert.caseDate': 'Case Date',
  'pages.MCAlert.txnDate': 'TXN Date',
  'pages.MCAlert.card': 'Card',
  'pages.MCAlert.status': 'Alert Status',
  'pages.MCAlert.mcRefundStatus': 'MCX Refund Status',
  'pages.MCAlert.card_last_4': 'Card last 4',
  'pages.MCAlert.card_first_6': 'Card Bin',
  'pages.MCAlert.crmOrderId': 'CRM Order ID',
  'pages.MCAlert.cardType': 'Card Type',
};
