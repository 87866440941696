import { localActions, localResources } from "./utils";

type AccessType = { [x: string]: boolean }

export default function access(
  initialState: { currentUser: CommonTypes.CurrentUser },
): AccessType {

  if (!initialState?.currentUser) return {} as AccessType;
  const { currentUser }: { currentUser: CommonTypes.CurrentUser } = initialState ?? {};
  const { permissions = {} } = currentUser
  const currentUserPermissions: Record<string, boolean> = {}

  localResources.forEach((lResource) => {

    // ? if there is no permission then dis-allow all permission 
    if (!Object.keys(permissions).length) {
      localActions.forEach((lAction) => {
        currentUserPermissions[`${lResource}.${lAction}`] = false
      })
    }

    // ? CHECK FOR WILD CARD
    // ? if there is wildcard permission for resource
    if (permissions?.["all"]) {
      localActions.forEach((lAction) => {
        // ? check actions that listed under 'all' and provide those permission only
        if (permissions?.["all"]?.includes(lAction)) {
          currentUserPermissions[`${lResource}.${lAction}`] = true
        }
        // ? else if action and resources has wildcard permission then give all permission
        else if (permissions?.["all"]?.includes("manage")) {
          currentUserPermissions[`${lResource}.${lAction}`] = true
        }
      })

    }
    else {
      localActions.forEach((lAction) => {
        //  ? if there is wildcard permission for actions then allow all action for all resources
        if (permissions?.[lResource]?.includes("manage")) {
          currentUserPermissions[`${lResource}.${lAction}`] = true
        }

        // ? otherwise provide permission that is mentioned in the DB
        else if (permissions?.[lResource]?.includes(lAction)) {
          currentUserPermissions[`${lResource}.${lAction}`] = true
        }
      })

    }


  })


  return {
    ...currentUserPermissions
  };
}

export const enum Permissions {
  READ_MID = 'mid.read',
  WILD_CARD = 'all.manage'
}
