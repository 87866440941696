import { JobSchemaType } from "@/pages/ManageExport/interfaces/interfaces";

export type ModalToolsType<T extends CommonTypes.MidListItem | CommonTypes.ClientType | CommonTypes.StatusChangeLog[] | CommonTypes.AlertType | JobSchemaType | CommonTypes.ChargebackListItem | CommonTypes.StatusChangeLog> = {
  openModal: boolean;
  data?: T;
};

const initialState = {
  openModal: false,
  data: undefined,
} as ModalToolsType<any>;

const store = {
  namespace: 'modalTools',
  state: initialState,
  reducers: {
    showModal(state: typeof initialState) {
      return { ...state, openModal: true };
    },
    hideModal(state: typeof initialState) {
      return { ...state, openModal: false };
    },
    saveData(state: typeof initialState, payload: any) {
      return { ...state, data: payload.payload };
    },
    saveDataAndOpen(state: typeof initialState, payload: any) {
      return { ...state, data: payload.payload, openModal: true };
    },
    clearData(state: typeof initialState) {
      return { ...state, data: undefined };
    },
    close(state: typeof initialState) {
      return { ...state, ...initialState };
    },
  },

  initialState: initialState,
};

export type ModalToolReducer = (typeof store)['reducers'];
export type ModalToolStateType = { modalTools: typeof initialState };

export default store;
