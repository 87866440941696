import { MenuProps } from "antd";

type ContextMenu = {
    isOpen: boolean,
    menuItems: MenuProps['items'],
    record: Record<string, any>;
}

const initialState = {
    isOpen: false,
    menuItems: [],
    record: {}
} as ContextMenu

const store = {
    namespace: 'contextMenu',
    state: initialState,
    reducers: {
        update(state: typeof initialState , payload : Omit<ContextMenu , "isOpen">) {
            return { ...state,  menuItems: payload?.menuItems , record: payload?.record};
        },
    },

    initialState: initialState,
};

export type ContextMenuReducer = (typeof store)['reducers'];
export type ContextMenuType = typeof initialState

export default store;
