import { AxiosResponse } from '@umijs/max';
import { AxiosInstance } from './http';

type LoginPayload = {
  username: string;
  password: string;
  autoLogin?: boolean;
};



export interface USER_API_TYPE {
  LoginPayload: LoginPayload;
  CurrentUser: CommonTypes.CurrentUser;
}

export const USER_API = {
  login: (payload: LoginPayload) => {
    return AxiosInstance.axiosRequest.post('auth/user/login', payload);
  },

  currentUser: (): Promise<
    AxiosResponse<{
      success: boolean;
      data: CommonTypes.CurrentUser;
    }>
  > => {
    return AxiosInstance.axiosRequest.get('/user/profile');
  },

  getUserList: async (
    params: CommonTypes.PageParams,
    sort: Record<string, any>,
  ): Promise<CommonTypes.UserListItem[]> => {
    const { current = 1, pageSize = 10, clientShortName, ...filters }: any = params;
    Object.entries(filters).forEach(([key, value]) => {
      if (value === 'None' || !value) {
        delete filters[key];
      }
    });
    const payload = {
      page: current,
      limit: pageSize,
      clientShortName,
      filters,
      sort,
    };
    const { data } = await AxiosInstance.axiosRequest.post('/user/list', payload);
    return data.data;
  },

  updateUserDetails: async (payload: Record<string, string>) => {
    return AxiosInstance.axiosRequest.put('/user', payload)
  },

  changePassword: async (payload: Record<string, string>) => {
    return AxiosInstance.axiosRequest.put('/user/password', payload)
  },

  forgotPassword: async (payload: Record<string, string>) => {
    return AxiosInstance.axiosRequest.post('/user/forgot-password', payload)
  },

  resetPassword: async (payload: CommonTypes.ResetPassword) => {
    return AxiosInstance.axiosRequest.post('/user/reset-password', payload)
  },

  createAdmin: (payload: Record<string, string>) => {
    return AxiosInstance.axiosRequest.post('/user/create-admin', payload)
  },
  deleteUser: (payload: Record<string, string>) => {
    return AxiosInstance.axiosRequest.patch('/user/delete-user', payload)
  },
  fetchConfig: () => {
    return AxiosInstance.axiosRequest.get('/config')
  }
};
