export default {
  'app.settings.menuMap.basic': 'মৌলিক বৈশিষ্ট্যসহ',
  'app.settings.menuMap.security': 'নিরাপত্তা বিন্যাস',
  'app.settings.menuMap.binding': 'অ্যাকাউন্ট বাঁধাই',
  'app.settings.menuMap.notification': 'নতুন বার্তা বিজ্ঞপ্তি',
  'app.settings.basic.avatar': 'অবতার',
  'app.settings.basic.change-avatar': 'অবতার পরিবর্তন করুন',
  'app.settings.basic.email': 'ইমেইল',
  'app.settings.basic.email-message': 'আপনার ইমেইল ইনপুট করুন!',
  'app.settings.basic.nickname': 'ডাক নাম',
  'app.settings.basic.nickname-message': 'আপনার ডাকনামটি ইনপুট করুন!',
  'app.settings.basic.profile': 'ব্যক্তিগত প্রোফাইল',
  'app.settings.basic.profile-message': 'আপনার ব্যক্তিগত প্রোফাইল ইনপুট করুন!',
  'app.settings.basic.profile-placeholder': 'নিজের সাথে সংক্ষিপ্ত পরিচয়',
  'app.settings.basic.country': 'দেশ/অঞ্চল',
  'app.settings.basic.country-message': 'আপনার দেশ ইনপুট করুন!',
  'app.settings.basic.geographic': 'প্রদেশ বা শহর',
  'app.settings.basic.geographic-message': 'আপনার ভৌগলিক তথ্য ইনপুট করুন!',
  'app.settings.basic.address': 'রাস্তার ঠিকানা',
  'app.settings.basic.address-message': 'দয়া করে আপনার ঠিকানা ইনপুট করুন!',
  'app.settings.basic.phone': 'ফোন নম্বর',
  'app.settings.basic.phone-message': 'আপনার ফোন ইনপুট করুন!',
  'app.settings.basic.update': 'তথ্য হালনাগাদ',
  'app.settings.security.strong': 'শক্তিশালী',
  'app.settings.security.medium': 'মধ্যম',
  'app.settings.security.weak': 'দুর্বল',
  'app.settings.security.password': 'অ্যাকাউন্টের পাসওয়ার্ড',
  'app.settings.security.password-description': 'বর্তমান পাসওয়ার্ড শক্তি',
  'app.settings.security.phone': 'সুরক্ষা ফোন',
  'app.settings.security.phone-description': 'আবদ্ধ ফোন',
  'app.settings.security.question': 'নিরাপত্তা প্রশ্ন',
  'app.settings.security.question-description':
    'সুরক্ষা প্রশ্ন সেট করা নেই, এবং সুরক্ষা নীতি কার্যকরভাবে অ্যাকাউন্ট সুরক্ষা রক্ষা করতে পারে',
  'app.settings.security.email': 'ব্যাকআপ ইমেইল',
  'app.settings.security.email-description': 'বাউন্ড ইমেইল',
  'app.settings.security.mfa': 'MFA ডিভাইস',
  'app.settings.security.mfa-description':
    "আনবাউন্ড এমএফএ ডিভাইস, বাঁধাইয়ের পরে, দু'বার নিশ্চিত করা যায়",
  'app.settings.security.modify': 'পরিবর্তন করুন',
  'app.settings.security.set': 'সেট',
  'app.settings.security.bind': 'বাঁধাই',
  'app.settings.binding.taobao': 'বাঁধাই তাওবাও',
  'app.settings.binding.taobao-description': 'বর্তমানে আনবাউন্ড তাওবাও অ্যাকাউন্ট',
  'app.settings.binding.alipay': 'বাইন্ডিং আলিপে',
  'app.settings.binding.alipay-description': 'বর্তমানে আনবাউন্ড আলিপে অ্যাকাউন্ট',
  'app.settings.binding.dingding': 'বাঁধাই ডিঙ্গটালক',
  'app.settings.binding.dingding-description': 'বর্তমানে আনবাউন্ড ডিঙ্গটাল অ্যাকাউন্ট',
  'app.settings.binding.bind': 'বাঁধাই',
  'app.settings.notification.password': 'অ্যাকাউন্টের পাসওয়ার্ড',
  'app.settings.notification.password-description':
    'অন্যান্য ব্যবহারকারীর বার্তাগুলি স্টেশন চিঠি আকারে জানানো হবে',
  'app.settings.notification.messages': 'সিস্টেম বার্তা',
  'app.settings.notification.messages-description':
    'সিস্টেম বার্তাগুলি স্টেশন চিঠির আকারে জানানো হবে',
  'app.settings.notification.todo': 'করণীয় বিজ্ঞপ্তি',
  'app.settings.notification.todo-description': 'করণীয় তালিকাটি স্টেশন থেকে চিঠি আকারে জানানো হবে',
  'app.settings.open': 'খোলা',
  'app.settings.close': 'বন্ধ',
};
