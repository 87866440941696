import { ProLayoutProps } from '@ant-design/pro-components';
import { COLOR } from './common';

/**
 * @name
 */
const Settings: ProLayoutProps & {
  pwa?: boolean;
  logo?: string;
} = {
  navTheme: 'light',
  colorPrimary: COLOR['@primary-color'],
  layout: 'mix',
  contentWidth: 'Fluid',
  fixedHeader: false,
  fixSiderbar: true,
  colorWeak: false,
  title: 'D6',
  pwa: true,
  logo: '/logo.png',
  iconfontUrl: '',
  headerTitleRender: (logo) => logo,
  token: {
    // See ts declaration, demo see document, modify style by token
    //https://procomponents.ant.design/components/layout#%E9%80%9A%E8%BF%87-token-%E4%BF%AE%E6%94%B9%E6%A0%B7%E5% BC%8F
  },
};

export default Settings;
