import { getFromLocalStorage, removeFromLocalStorage, setLocalStorage } from '.';

export const TOKEN_LOCAL_STORAGE_NAME = '<%admin_auth%>';

export const tokenProvider = {
  get: (): CommonTypes.LocalTokenTypes | null => {
    const tokens = getFromLocalStorage(TOKEN_LOCAL_STORAGE_NAME) as
      | { accessToken?: string; refreshToken?: string }
      | any;

    if (Object.keys(tokens).length <= 0) {
      return null;
    }

    return tokens;
  },
  set: (tokens: CommonTypes.LocalTokenTypes) => {
    const prevTokens = tokenProvider.get();

    if (!prevTokens) {
      setLocalStorage(TOKEN_LOCAL_STORAGE_NAME, JSON.stringify(tokens));
      return;
    }

    const _temp = JSON.stringify(Object.assign(prevTokens, tokens));
    setLocalStorage(TOKEN_LOCAL_STORAGE_NAME, _temp);
    return;
  },
  format: (tokens: any): CommonTypes.LocalTokenTypes => {
    return {
      accessToken: tokens?.access_token ?? tokens?.accessToken ?? '',
      refreshToken: tokens?.refresh_token ?? tokens?.refreshToken ?? '',
    };
  },
  delete: () => {
    removeFromLocalStorage(TOKEN_LOCAL_STORAGE_NAME);
  },
};
