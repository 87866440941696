type TableRowSize = 'large' | 'middle' | 'small';

export type TablesToolsType = {
  rowSizeType: TableRowSize;
  pageSize: number;
};

const initialState = {
  chargebackTable: {
    rowSizeType: 'middle',
    pageSize: 10,
  } as TablesToolsType,

  midTable: {
    rowSizeType: 'middle',
    pageSize: 10,
  } as TablesToolsType,

  clientTable: {
    rowSizeType: 'middle',
    pageSize: 10,
  } as TablesToolsType,

  userTable: {
    rowSizeType: 'middle',
    pageSize: 10,
  } as TablesToolsType,

  clientUserTable: {
    rowSizeType: 'middle',
    pageSize: 10,
  } as TablesToolsType,

  rdrAlertTable: {
    rowSizeType: 'middle',
    pageSize: 10,
  } as TablesToolsType,

  mcAlertTable: {
    rowSizeType: 'middle',
    pageSize: 10,
  } as TablesToolsType,

  jobTable: {
    rowSizeType: 'middle',
    pageSize: 10,
  } as TablesToolsType,

  dashboardTable: {
    rowSizeType: 'middle',
    pageSize: 10,
  } as TablesToolsType,
};

const store = {
  namespace: 'tablesTools',
  state: initialState,
  reducers: {
    add(
      state: typeof initialState,
      {
        payload,
      }: {
        payload: {
          data: any;
          key: string;
        };
      },
    ) {
      if (!payload?.key.includes('|')) throw new Error('key is not set properly!!');

      const [tableName, property] = payload?.key.split('|');

      const tableType = tableName as keyof typeof initialState;
      const propertyType = property as keyof (typeof initialState)['chargebackTable'];

      return {
        ...state,
        [tableType]: {
          ...state[tableType],
          [propertyType]: payload?.data,
        },
      };
    },
  },
  initialState: initialState,
};

export type TableToolReducer = (typeof store)['reducers'];
export type TableToolStateType = { tablesTools: typeof initialState };

export default store;
