import { TimeRangePickerProps } from 'antd';
import dayjs from 'dayjs';
import moment from 'moment';
import React, { ComponentClass, FunctionComponent } from 'react';
import { AlertStatusEnum, ChargebackStatusEnum, MCAlertStatusEnum } from './status';

export enum MODULE_ENUM {
  CHARGEBACKS = 'chargebacks',
  RDR_ALERTS = 'rdrAlerts',
  MC_ALERTS = 'mcAlerts'
}

export const initialMTDDateRange = [
  dayjs(dayjs().startOf('month').format('MM/DD/YYYY')),
  dayjs(dayjs().format('MM/DD/YYYY')),
];
export const getFromLocalStorage = (key: string) => {
  return JSON.parse(localStorage.getItem(key) ?? '{}') ?? {};
};

export const setLocalStorage = (key: string, value: string): void => {
  localStorage.setItem(key, value);
};

export const removeFromLocalStorage = (key: string) => {
  return localStorage.removeItem(key);
};

export const LOCAL_PATH = {
  login: '/user/login',
  resetPassword: '/user/reset-password',
  forgotPassword: '/user/forgot-password',
};

export const currencyFormatter = (amount: number): string => {
  return `$${(amount / 100).toFixed(2)}`;
};

export const cardTypeFormatter = (chargebackData: Record<string, any>): string => {
  const cardType = chargebackData?.card_type ? chargebackData?.card_type : '';
  const cardFirstSix = chargebackData?.card_first_6 ? chargebackData?.card_first_6 : '';
  const cardLastFour = chargebackData?.card_last_4 ? chargebackData?.card_last_4 : '';
  const asterix = cardFirstSix && cardLastFour ? '*' : '';
  const hyphen = cardType ? '-' : '';
  return `${cardType}${hyphen}${cardFirstSix}${asterix}${cardLastFour}`;
};

export const DateFormatter = (date: Date | string | number, dateFormat = 'MM/DD/YYYY') => {
  return moment(date).isValid() ? moment(date).format(dateFormat) : '-';
};

export const isEmpty = (value: any) => {
  if (Number.isFinite(value)) return false;
  if (!value) return true;
  if (Array.isArray(value)) return value.length <= 0;
  if (typeof value === 'string') return true;
  if (value && typeof value === 'object' && Object.keys(value).length === 0) return true;
  if (typeof value === 'boolean') return value;
  return false;
};

export const localActions = ['read', 'create', 'update', 'delete'];
export const localResources = [
  'mid',
  'client',
  'chargeback',
  'profile',
  'alert',
  'admin',
  'job',
  'clientCBAlertCount',
  'mcAlert'
];

export const getLocalPermissions = () => {
  const array: string[] = [];
  localResources.forEach((resource) => {
    localActions.forEach((action) => {
      array.push(`${resource}.${action}`);
    });
  });
  return array;
};

export function beautifyString(inputString: string) {
  if (!inputString || typeof inputString !== 'string') {
    return '';
  }
  // Remove special characters and replace them with spaces
  let beautifiedString = inputString.replace(/[^a-zA-Z0-9]+/g, ' ');

  // Handle UPPER_CASE, camelCase, PascalCase, and snake_case
  beautifiedString = beautifiedString.replace(
    /([a-z])([A-Z])/g,
    (_, lower, upper) => `${lower} ${upper.toLowerCase()}`,
  );

  beautifiedString = beautifiedString.replace(/_/g, ' ');

  // Capitalize the first letter of each word
  beautifiedString = beautifiedString
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');

  return beautifiedString.trim();
}

export const clientFilter = (
  data: Record<string, any>[],
  filterString: boolean | string = false,
) => {
  let clientData = data;
  if (filterString) clientData = data?.filter((record) => record?.crm?.crm !== filterString);
  const clientFilterArray = clientData?.reduce(
    (prev: Record<string, string>[], curr: Record<string, string>) => {
      prev.push({ label: curr?.clientShortName, value: curr?.clientShortName });
      return prev;
    },
    [],
  );
  return clientFilterArray;
};

//? This is a build wrapping React.createElement
export class ReactElementBuilder {
  private static element:
    | string
    | FunctionComponent<Record<string, any>>
    | ComponentClass<Record<string, any>, any> = '';
  private static props: Record<string, any> = {};
  private static children: any[] = [];

  static setElement(
    type:
      | string
      | FunctionComponent<Record<string, any>>
      | ComponentClass<Record<string, any>, any>,
  ) {
    this.element = type;
    return this;
  }

  static setProps(props: Record<string, any>) {
    this.props = props;
    return this;
  }

  static setChildren(...children: any[]) {
    this.children = children;
    return this;
  }

  static build() {
    if (!this.element) throw new Error('Invalid react element!');
    return React.createElement(this.element, this.props, ...this.children);
  }
}

export const extractHost = (url: string) => {
  if (!url) return '';

  const urlParse = new URL(url);

  if (!urlParse?.host) return '';

  return urlParse?.host;
};

export const rangePresets: TimeRangePickerProps['presets'] = [
  { label: 'Today', value: [dayjs(), dayjs()] },
  { label: 'Yesterday', value: [dayjs().add(-1, 'd'), dayjs().add(-1, 'd')] },
  {
    label: 'Last 7 Days',
    value: [dayjs().add(-6, 'd'), dayjs()],
  },
  {
    label: 'Last Week',
    value: [
      dayjs().subtract(1, 'week').startOf('week').add(1, 'd'),
      dayjs().subtract(1, 'week').endOf('week').add(1, 'd'),
    ],
  },
  {
    label: 'Last 30 Days',
    value: [dayjs().add(-29, 'd'), dayjs()],
  },
  {
    label: 'Last 60 Days',
    value: [dayjs().add(-59, 'd'), dayjs()],
  },
  {
    label: 'Last 90 Days',
    value: [dayjs().add(-89, 'd'), dayjs()],
  },
  {
    label: 'Last Month',
    value: [
      dayjs().subtract(1, 'month').startOf('month'),
      dayjs().subtract(1, 'month').endOf('month'),
    ],
  },
  { label: 'MTD', value: [dayjs().startOf('month'), dayjs()] },
  { label: 'YTD', value: [dayjs().startOf('year'), dayjs()] },
];

export const tierDropdownList = [
  { label: 'Tier 1', value: 'Tier 1' },
  { label: 'Tier 2', value: 'Tier 2' },
];

export function hasClassOrAncestorWithClass(element: any, className: string) {
  if (element?.classList?.contains(className)) return true;

  let parent = element?.parentElement;

  while (parent) {
    if (parent?.classList?.contains(className)) {
      return true;
    }
    parent = parent?.parentElement;
  }

  return false;
}

export const getAlternateClients = (AlternateClients: CommonTypes.AlternateClientType[]) => {
  const formattedClients = AlternateClients?.reduce(
    (previous: string[], currentClient: CommonTypes.AlternateClientType) => {
      previous.push(currentClient?.clientShortName);
      return previous;
    },
    [],
  );
  return formattedClients;
};

const convertTonumberWhiteList = ['auto_inc_id', "transaction_amount"]

const checkValidNumber = <T,>(key: keyof T, filterValue: string) => {
  if (convertTonumberWhiteList.includes(key as never)) {
    const getNumber = filterValue && !isNaN(filterValue as unknown as number) ? Number(filterValue) : null
    return getNumber
  }
  return filterValue
}

export const commaSeparatedValueToArray = <T extends Record<string, any>,>(
  key: keyof T,
  value: any,
  filters: T,
  whiteList: (keyof T)[],
) => {
  if (value) {
    if (typeof value === 'string') {
      if (value.includes(',') && whiteList.includes(key)) {
        const valueArray = value?.split?.(',');
        const trimValues = valueArray?.reduce((prev, current) => {
          const filterValue = checkValidNumber(key, current.trim())
          if (filterValue) prev?.push(filterValue as never);
          return prev;
        }, [] as string[]);
        filters[key] = trimValues as never;
      } else {
        const filterValue = checkValidNumber(key, value.trim())
        filters[key] = filterValue as never;
      }
    }
  }
};

export const structureData = (metaData: any) => {
  const firstLevelData = {} as any;
  const objectData = {} as any;
  const arrayData = {} as any;

  Object.entries(metaData).map(([key, value]) => {
    if (Array.isArray(value)) arrayData[key] = value;
    else if (typeof value === 'object' && !Array.isArray(value) && value !== null)
      objectData[key] = value;
    else firstLevelData[key] = value;

    return 0;
  });

  const finalObject = { ...firstLevelData, ...objectData, ...arrayData };
  return finalObject;
};

export const convertToCents = (amount: string) => {
  return parseInt((Math.abs(parseFloat(amount)) * 100).toFixed(0))
}


export const isRenderSelectAll = (status: ChargebackStatusEnum | AlertStatusEnum | MCAlertStatusEnum, errors: string[]) =>
  errors.includes(status);


export const checkifTimeMoreThan24Hours = (dateTime: string): number => {
  if (dateTime) {
    const currentDate = dayjs().tz('UTC');
    const providedDate = dayjs(dateTime).tz('UTC');

    const difference = currentDate.diff(providedDate, 'hours');

    return difference;
  } return 0;
}

export const getLastCheckedColor = (dateAndTime: string) => {
  const color = checkifTimeMoreThan24Hours(dateAndTime) > 24 ? 'red-color' : 'bold-color ';
  return checkifTimeMoreThan24Hours(dateAndTime) > 0 ? color : 'bold-color';
}

export const getDate = (date: string[], initialDateMTD = false) => {
  if (date?.length > 0) {
    const [startDate, endDate] = date;
    return [
      dayjs(dayjs(startDate).format('MM/DD/YYYY')),
      dayjs(dayjs(endDate).format('MM/DD/YYYY')),
    ];
  }
  if (initialDateMTD) {
    return [
      dayjs(dayjs().startOf('month').format('MM/DD/YYYY')),
      dayjs(dayjs().format('MM/DD/YYYY')),
    ];
  }
  return [];
};

export enum Portal {
  PAYSAFE = 'www.paymentxp.com',
  HUMBOLDT = 'www.paymentshub.com',
  MERCHANT_INDUSTRIES = 'portal.isoaccess.com',
  MAVERICK = 'dashboard.maverickpayments.com',
  SIGNAPAY = 'signapay.netevia.com',
  UNIFIED = 'hq.netevia.com',
  PRIORITY = 'mxmerchant.com',
  CORVIA = 'elevateqs.com',
  MISSION_VALLEY = 'hub.beyondbancard.com',
  QUANTUM = 'quantum.merchantportfoliomanager.com',
  IPPAY = 'ipreporting.com',
  PAYFACTO = 'us.payfacto.com',
  BLUESNAP = 'bluesnap.com',
  PAYARC = 'dashboard.payarc.net',
  GLACIER_PAYMENTS = 'hub.glacierpayments.com',
  CELERO = 'celerocompass.com',
  NEXIOPAY = 'dashboard.nexiopay.com'
}

export const PortalToProcessorsMap: Record<Portal, string> = {
  [Portal.SIGNAPAY]: 'Signapay',
  [Portal.PAYSAFE]: 'paysafe',
  [Portal.HUMBOLDT]: 'humboldt',
  [Portal.MERCHANT_INDUSTRIES]: 'Merchant Industries',
  [Portal.MAVERICK]: 'maverick',
  [Portal.UNIFIED]: 'unified',
  [Portal.PRIORITY]: 'priority',
  [Portal.CORVIA]: 'corvia',
  [Portal.MISSION_VALLEY]: 'missionValley',
  [Portal.QUANTUM]: 'quantum',
  [Portal.IPPAY]: 'ipPay',
  [Portal.PAYFACTO]: 'payfacto',
  [Portal.BLUESNAP]: 'bluesnap',
  [Portal.PAYARC]: 'payarc',
  [Portal.GLACIER_PAYMENTS]: 'glacierPayments',
  [Portal.CELERO]: 'celero',
  [Portal.NEXIOPAY]: 'nexiopay'
};