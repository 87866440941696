export default {
  'app.settings.menuMap.basic': 'تنظیمات پایه ',
  'app.settings.menuMap.security': 'تنظیمات امنیتی',
  'app.settings.menuMap.binding': 'صحافی حساب',
  'app.settings.menuMap.notification': 'اعلان پیام جدید',
  'app.settings.basic.avatar': 'آواتار',
  'app.settings.basic.change-avatar': 'آواتار را تغییر دهید',
  'app.settings.basic.email': 'ایمیل',
  'app.settings.basic.email-message': 'لطفا ایمیل خود را وارد کنید!',
  'app.settings.basic.nickname': 'نام مستعار',
  'app.settings.basic.nickname-message': 'لطفاً نام مستعار خود را وارد کنید!',
  'app.settings.basic.profile': 'پروفایل شخصی',
  'app.settings.basic.profile-message': 'لطفاً مشخصات شخصی خود را وارد کنید!',
  'app.settings.basic.profile-placeholder': 'معرفی مختصر خودتان',
  'app.settings.basic.country': 'کشور / منطقه',
  'app.settings.basic.country-message': 'لطفاً کشور خود را وارد کنید!',
  'app.settings.basic.geographic': 'استان یا شهر',
  'app.settings.basic.geographic-message': 'لطفاً اطلاعات جغرافیایی خود را وارد کنید!',
  'app.settings.basic.address': 'آدرس خیابان',
  'app.settings.basic.address-message': 'لطفا آدرس خود را وارد کنید!',
  'app.settings.basic.phone': 'شماره تلفن',
  'app.settings.basic.phone-message': 'لطفاً تلفن خود را وارد کنید!',
  'app.settings.basic.update': 'به روز رسانی اطلاعات',
  'app.settings.security.strong': 'قوی',
  'app.settings.security.medium': 'متوسط',
  'app.settings.security.weak': 'ضعیف',
  'app.settings.security.password': 'رمز عبور حساب کاربری',
  'app.settings.security.password-description': 'قدرت رمز عبور فعلی',
  'app.settings.security.phone': 'تلفن امنیتی',
  'app.settings.security.phone-description': 'تلفن مقید',
  'app.settings.security.question': 'سوال امنیتی',
  'app.settings.security.question-description':
    'سوال امنیتی تنظیم نشده است و سیاست امنیتی می تواند به طور موثر از امنیت حساب محافظت کند',
  'app.settings.security.email': 'ایمیل پشتیبان',
  'app.settings.security.email-description': 'ایمیل مقید',
  'app.settings.security.mfa': 'دستگاه MFA',
  'app.settings.security.mfa-description':
    'دستگاه MFA بسته نشده ، پس از اتصال ، می تواند دو بار تأیید شود',
  'app.settings.security.modify': 'تغییر',
  'app.settings.security.set': 'تنظیم',
  'app.settings.security.bind': 'بستن',
  'app.settings.binding.taobao': 'اتصال Taobao',
  'app.settings.binding.taobao-description': 'حساب Taobao در حال حاضر بسته نشده است',
  'app.settings.binding.alipay': 'اتصال Alipay',
  'app.settings.binding.alipay-description': 'حساب Alipay در حال حاضر بسته نشده است',
  'app.settings.binding.dingding': 'اتصال DingTalk',
  'app.settings.binding.dingding-description': 'حساب DingTalk در حال حاضر محدود نشده است',
  'app.settings.binding.bind': 'بستن',
  'app.settings.notification.password': 'رمز عبور حساب کاربری',
  'app.settings.notification.password-description':
    'پیام های سایر کاربران در قالب یک نامه ایستگاهی اعلام خواهد شد',
  'app.settings.notification.messages': 'پیام های سیستم',
  'app.settings.notification.messages-description':
    'پیام های سیستم به صورت نامه ایستگاه مطلع می شوند',
  'app.settings.notification.todo': 'اعلان کارها',
  'app.settings.notification.todo-description':
    'لیست کارها به صورت نامه ای از ایستگاه اطلاع داده می شود',
  'app.settings.open': 'باز کن',
  'app.settings.close': 'بستن',
};
