import { Portal } from '@/utils';

export type InfoType = Record<
  string,
  {
    color: string;
    message: string;
    label: string;
  }
>;

export type GenericStatusArrayType = Array<string>;

export interface GenericStatusList {
  errors: GenericStatusArrayType;
  success: GenericStatusArrayType;
  all: GenericStatusArrayType;
  marked: GenericStatusArrayType;
}

export interface MCAlertStatusList extends Omit<GenericStatusList, 'errors'> {
  errors: {
    total: Array<string>;
    withoutCredits: Array<string>;
  };
  creditRequest: Array<string>;
  hideInfoIconForCaseDate: Array<string>;
}

export interface MarkConfig<T extends GenericStatusList | MCAlertStatusList = GenericStatusList> {
  info: InfoType;
  list: T;
}

type AppConfigType = {
  status?: Record<string, string>[];
  error?: string;
  isLoading?: boolean;
  data: {
    chargeback: MarkConfig;
    rdrStatus: MarkConfig;
    mcxStatus: MarkConfig;
    processors: {
      all: Portal[];
      tsys: Portal[];
      cbImport: Portal[];
      info: { value: string; label: string }[];
    };
    version: string;
  };
};

const initialState = {
  status: undefined,
  error: '',
  isLoading: false,
  data: {} as AppConfigType['data'],
} as AppConfigType;

const store = {
  namespace: 'appConfig',
  state: initialState,
  reducers: {
    fetchAppConfig(state: typeof initialState) {
      return { ...state, isLoading: true, data: {}, error: '' };
    },
    saveAppConfig(state: typeof initialState, payload: any) {
      return { ...state, isLoading: false, data: payload.payload, error: '' };
    },
  },

  initialState: initialState,
};

export type AppConfigsReducer = (typeof store)['reducers'];
export type AppConfigStateType = { appConfig: typeof initialState };

export default store;
