export const tableSorting = (sort: Record<string, any>) => {
    let _sort: Record<string, any> = { 'createdAt': -1 }
    if (sort) {
        Object.entries(sort)?.forEach(([key, value]) => {
            delete _sort['createdAt'];
            if (value === 'ascend') _sort[key] = 1

            else if (value === 'descend') _sort[key] = -1
        })
    }
    return _sort;
}