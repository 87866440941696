import { LOCAL_PATH } from '@/utils';
import { tokenProvider } from '@/utils/tokens.utils';
import { history } from '@umijs/max';
import axios, { AxiosError } from 'axios';

const REFRESH_WHITELIST_ROUTES = ['login'];

const mockURL = MOCK === 'none' ? BASE_URL : 'http://localhost:8000';
const API_BASE_URL = REACT_APP_ENV === 'prod' ? BASE_URL : mockURL;
export class AxiosInstance {
  static axiosRequest = axios.create({
    timeout: 60000 * 2,
    baseURL: API_BASE_URL,
    headers: { 'Content-Type': 'application/json' },
  });
}

AxiosInstance.axiosRequest.interceptors.request.use(async (config) => {
  try {
    const tokens = tokenProvider.get();

    if (!tokens) return config;

    if (tokens?.accessToken) config.headers['authorization'] = `Bearer ${tokens.accessToken}`;

    return config;
  } catch (err) {
    console.error('Axios Error: ', err);
    return Promise.reject(err);
  }
});

AxiosInstance.axiosRequest.interceptors.response.use(
  (successResponse) => successResponse,
  async (error: AxiosError) => {
    const originalRequest = error?.config;

    if (!error?.response || Object.keys(error?.response).length <= 0) return Promise.reject(error);

    if (
      error.response.status === 401 &&
      !REFRESH_WHITELIST_ROUTES.includes(String(originalRequest?.url))
    ) {
      tokenProvider.delete();
      history.replace(LOCAL_PATH.login);
      return Promise.reject(error);
    }

    // if (error.response.status === 401 && originalRequest && !originalRequest?._retry) {
    //   // @ts-ignore
    //   originalRequest['_retry'] = true;

    //   const tokens = tokenProvider.get()
    //   if (!tokens) return Promise.reject(error);

    //   const response = await axios.post(`${BASE_URL}/auth/refresh`, { access_token: tokens?.accessToken }, {
    //     headers: {
    //       'authorization': 'Bearer ' + tokens?.refreshToken
    //     },
    //   })
    //     .then(async ({ data }) => {
    //       const newTokens = tokenProvider.format(data?.data)
    //       tokenProvider.set(newTokens)
    //       originalRequest.headers["Authorization"] = `Bearer ${newTokens?.accessToken}`
    //       return await axios.request(originalRequest);
    //     })

    //     .catch(err => console.error("Error From Refresh", err))

    //   return Promise.resolve(response)
    // }
    return Promise.reject(error);
  },
);
