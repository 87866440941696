export default {
  'menu.welcome': 'স্বাগতম',
  'menu.more-blocks': 'আরও ব্লক',
  'menu.home': 'নীড়',
  'menu.admin': 'অ্যাডমিন',
  'menu.admin.sub-page': 'উপ-পৃষ্ঠা',
  'menu.login': 'প্রবেশ',
  'menu.register': 'নিবন্ধন',
  'menu.register-result': 'নিবন্ধনে ফলাফল',
  'menu.dashboard': 'ড্যাশবোর্ড',
  'menu.dashboard.analysis': 'বিশ্লেষণ',
  'menu.dashboard.monitor': 'নিরীক্ষণ',
  'menu.dashboard.workplace': 'কর্মক্ষেত্র',
  'menu.exception.403': '403',
  'menu.exception.404': '404',
  'menu.exception.500': '500',
  'menu.form': 'ফর্ম',
  'menu.form.basic-form': 'বেসিক ফর্ম',
  'menu.form.step-form': 'পদক্ষেপ ফর্ম',
  'menu.form.step-form.info': 'পদক্ষেপ ফর্ম (স্থানান্তর তথ্য লিখুন)',
  'menu.form.step-form.confirm': 'পদক্ষেপ ফর্ম (স্থানান্তর তথ্য নিশ্চিত করুন)',
  'menu.form.step-form.result': 'পদক্ষেপ ফর্ম (সমাপ্ত)',
  'menu.form.advanced-form': 'উন্নত ফর্ম',
  'menu.list': 'তালিকা',
  'menu.list.table-list': 'অনুসন্ধানের টেবিল',
  'menu.list.basic-list': 'বেসিক তালিকা',
  'menu.list.card-list': 'কার্ডের তালিকা',
  'menu.list.search-list': 'অনুসন্ধানের তালিকা',
  'menu.list.search-list.articles': 'অনুসন্ধানের তালিকা (নিবন্ধসমূহ)',
  'menu.list.search-list.projects': 'অনুসন্ধানের তালিকা (প্রকল্পগুলি)',
  'menu.list.search-list.applications': 'অনুসন্ধানের তালিকা (অ্যাপ্লিকেশন)',
  'menu.profile': 'প্রোফাইল',
  'menu.profile.basic': 'বেসিক প্রোফাইল',
  'menu.profile.advanced': 'উন্নত প্রোফাইল',
  'menu.result': 'ফলাফল',
  'menu.result.success': 'সাফল্য',
  'menu.result.fail': 'ব্যর্থ',
  'menu.exception': 'ব্যতিক্রম',
  'menu.exception.not-permission': '403',
  'menu.exception.not-find': '404',
  'menu.exception.server-error': '500',
  'menu.exception.trigger': 'ট্রিগার',
  'menu.account': 'হিসাব',
  'menu.account.center': 'অ্যাকাউন্ট কেন্দ্র',
  'menu.account.settings': 'অ্যাকাউন্ট সেটিংস',
  'menu.account.trigger': 'ট্রিগার ত্রুটি',
  'menu.account.logout': 'প্রস্থান',
  'menu.editor': 'গ্রাফিক সম্পাদক',
  'menu.editor.flow': 'ফ্লো এডিটর',
  'menu.editor.mind': 'মাইন্ড এডিটর',
  'menu.editor.koni': 'কোনি সম্পাদক',
};
