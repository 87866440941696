type ClientType = {
    data?: { clientName: string, clientShortName: string }[]
    error?: string,
    isLoading?: boolean
}

const initialState = {
    data: undefined,
    error: "",
    isLoading: false
} as ClientType

const store = {
    namespace: 'clients',
    state: initialState,
    reducers: {
        fetchClient(state: typeof initialState) {
            return { ...state, isLoading: true, data: undefined, error: "" };
        },
        saveData(state: typeof initialState, payload: any) {
            return { ...state, isLoading: false, data: payload.payload, error: "" };
        },
        saveErrorMessage(state: typeof initialState, payload: any) {
            return { ...state, isLoading: false, data: undefined, error: payload.payload };
        }
    },

    initialState: initialState,
};

export type ClientReducer = (typeof store)['reducers'];
export type ClientStateType = { clients: typeof initialState };

export default store;
