import { CRMPayload } from '@/components/CRM/interface';
import { AxiosInstance } from './http';

export const CLIENT_API = {
  addClientWithUser: (payload: Record<string, string>) => {
    return AxiosInstance.axiosRequest.post('/user/add', payload);
  },

  getClients: async (
    params: CommonTypes.PageParams,
    sort: Record<string, any>,
  ): Promise<CommonTypes.ClientType[]> => {
    const { current = 1, pageSize = 10, ...filters }: any = params;
    Object.entries(filters).forEach(([key, value]) => {
      if (value === 'None' || !value) {
        delete filters[key];
      }
    });
    const payload = {
      page: current,
      limit: pageSize,
      filters,
      sort,
    };
    const { data } = await AxiosInstance.axiosRequest.post('/clients', payload);
    return data.data;
  },

  getClientUsers: async (
    params: CommonTypes.PageParams,
    sort: Record<string, any>,
    clientShortName: string,
  ): Promise<CommonTypes.UserListItem[]> => {
    const { current = 1, pageSize = 10, ...filters }: any = params;
    Object.entries(filters).forEach(([key, value]) => {
      if (value === 'None' || !value) {
        delete filters[key];
      }
    });
    const payload = {
      page: current,
      limit: pageSize,
      filters,
      sort,
      clientShortName,
    };
    const { data } = await AxiosInstance.axiosRequest.post('/clients/users', payload);
    return data.data;
  },

  updateCRM: async (payload: CRMPayload) => {
    const response = await AxiosInstance.axiosRequest.post('clients/crm/create', payload);
    return response;
  },

  getClientsName: async () => {
    return AxiosInstance.axiosRequest.get('clients/name').then(res => res?.data?.data);
  },

  addAlternateClients: async (payload: Record<string, any>) => {
    return await AxiosInstance.axiosRequest.post('clients/alternate-clients', payload);
  }
};
