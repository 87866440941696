export const USER_LIST = {
    firstName: "firstName",
    lastName: "lastName",
    email: "email"
}

export const CHARGEBACK_LIST = {
    auto_inc_id: "auto_inc_id",
    mid: "mid",
    clientId: "clientId",
    processor: "processor",
    arn: "arn",
    amount: "amount",
    txnDate: "txn_date",
    caseDate: "case_date",
    reasonCode: "reasonCode",
    card_type: "card_type",
    card_first_6: "card_first_6",
    card_last_4: "card_last_4",
    status: "status",
    portal: "portal",
    crmId: "crmId",
    reason_code: "reason_code",
    reason_description: "reason_description"
}


export const CLIENT_LIST = {
    clientName: "clientName",
    clientShortName: "clientShortName",
    crm: { crm: 'crm' },
    clientUser: { email: 'email' }

}

export const MIDDATA_LIST = {
    clientId: "clientId",
    crmId: "crmId",
    mid: "mid",
    processor: "processor",
    dba: "dba",
    descriptor: "descriptor",
    email: "email",
    rdrDate: "rdrDate",
    status: "status",
    updatedAt: "updatedAt",
    mtdCBCount: 'mtdCBCount',
    mtdTxnCount: 'mtdTxnCount',
    mtdCBRatio: 'mtdCBRatio',
    bank: 'bank',
    portal: 'portal',
    rdrVerifiName: 'rdrVerifiName',
    rpa_last_runAt: 'rpa_last_runAt',
    rpa_errorAt: 'rpa_errorAt',
    mtdRdrAlCount: 'mtdRdrAlCount',
    mcAlLastImportedAt: 'mcAlLastImportedAt',
    cbLastImportedAt: 'cbLastImportedAt',
    rdrAlLastImportedAt: 'rdrAlLastImportedAt',
    lastCheckedAt: 'lastCheckedAt',
    mtdMcAlCount: 'mtdMcAlCount',
    mtdActualCBCount: 'mtdActualCBCount',
    lastMTDUpdate: 'lastMTDUpdate',
    mtdVisaCbCount: 'mtdVisaCbCount',
    mtdMasterCbCount: 'mtdMasterCbCount'
}

export const ALERT_TYPE = {
    _id: '_id',
    auto_inc_id: 'auto_inc_id',
    caseId: 'caseId',
    mid: 'mid',
    clientId: 'clientId',
    type: 'type',
    reasonCode: 'reasonCode',
    merchantOrderId: 'merchant_orderId',
    receivedDate: 'receivedDate',
    caseAge: 'caseAge',
    statusCode: 'statusCode',
    merchantName: 'merchant_name',
    descriptor: 'descriptor',
    descriptorContact: 'descriptorContact',
    caseAmount: 'case_amount',
    paymentType: 'payment_type',
    accountNumber: 'account_number',
    accountExpirationDate: 'accountExpirationDate',
    transactionDate: 'transaction_date',
    transactionAmount: 'transactionAmount',
    authorizationCode: 'authorizationCode',
    arn: 'arn',
    creditDate: 'creditDate',
    creditAmount: 'creditAmount',
    timeToResolve: 'timeToResolve',
    transactionBin: 'transactionBin',
    issuerName: 'issuerName',
    issuerCaseNumber: 'issuerCaseNumber',
    transactionCurrencyCode: 'transactionCurrencyCode',
    caseCurrencyCode: 'caseCurrencyCode',
    status: 'status',
    markedStatus: 'marked_status',
    caseReceivedDate: 'case_received_date',
    pricingTier: 'pricing_tier',
    action: 'action',
    crmId: 'crmId'
}

export const DASHBOARD_DATA_LIST = {
    clientShortName: 'clientShortName',
    chargebacks: 'totalChargebacks',
    alerts: 'totalAlerts',
    cbMarkedCount: 'markedChargebacks',
    cbErrorCount: 'errorChargebacks',
    alertTierCount1: 'tier1Count',
    alertTierCount2: 'tier2Count',
    alertErrorCount: 'alertErrorCount',
    caseDate: 'caseDate',
    markedAlerts: 'markedAlerts'
}

export const MC_ALERT_TYPE = {
    auto_inc_id: 'auto_inc_id',
    caseId: 'caseId',
    mid: 'mid',
    clientId: 'clientId',
    crm: 'crm',
    transaction_amount: 'transaction_amount',
    mc_created_at: 'mc_created_at',
    transaction_date: 'transaction_date',
    card_first_6: 'card_first_6',
    card_last_4: 'card_last_4',
    marked_status: 'marked_status',
    crmId: 'crmId',
    fdef_mark_status: 'fdef_mark_status',
    authorization_code: 'authorization_code',
    card_type: 'card_type',
    arn: 'arn'
}